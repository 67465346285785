import React from 'react';
import {
  Container,
  Columns,
  Column,
  Tile,
  Button,
} from '@codeparva/ui-components-library';
import { Text } from '@codeparva/gatsby-theme-layout-components/src/components/textVariations';
import { FontAwesomeIcon } from '@codeparva/gatsby-theme-layout-components/src/utilities/iconLoader/FontAwesomeIcon';
import { weakKey } from '@codeparva/gatsby-theme-layout-components/src/utilities/weakKey';
import { JumbotronTypes } from '@codeparva/gatsby-theme-layout-components/src/sections/homePage/components/jumbotron/jumbotronTypes';
import { useJumbotronContext } from '@codeparva/gatsby-theme-layout-components/src/sections/homePage/components/jumbotron/context/jumbotronContext';

export const JumbotronTitleAndLocationSelectSingleLocation = ({
  contactDetails,
  isImage,
}: JumbotronTypes.TJumbotronTitleLocationSingle) => {
  const jumbotronContext = useJumbotronContext();
  return (
    <div className="pageContentTitle is-flex is-align-items-center is-flex-direction-column">
      <img src='https://tfw-v11-assets.s3.amazonaws.com/4_U_logo_a41fb04d54.gif' style={{width:'200px',height:'100px',background:'rgba(255,255,255,0.8)',padding:'0.5rem'}}></img>
      <p className="subtitle1">
        {jumbotronContext.jumbotron_subtitle.description}
      </p>
      <div>
        <a
          href={
            contactDetails[0]?.address && contactDetails[0]?.address[0]!.link
          }
          className={`is-flex is-align-items-center ${
            isImage ? 'has-text-secondary' : 'has-text-primary'
          } subtitle1`}
          target="_blank"
        >
          <FontAwesomeIcon
            iconClassName={
              (contactDetails[0]?.address &&
                contactDetails[0]?.address[0]?.icon?.iconClassName!) ||
              'FaMapMarker'
            }
            iconContextProps={{
              className: `${
                isImage ? 'has-text-secondary' : 'has-text-primary'
              } pr-3`,
              style: {
                fontSize:
                  (contactDetails[0]?.address &&
                    contactDetails[0]?.address[0]?.icon?.size) ||
                  28,
              },
            }}
          />
          {contactDetails[0]?.address && contactDetails[0]?.address[0].data}
        </a>
      </div>
    </div>
  );
};
export const JumbotronTitleAndLocationSelectMultiLocation = React.forwardRef(
  (
    {
      selectedLocation,
      isImage,
      locationData,
      dropDownState,
      onDropDownButton,
      selectItemFromDropDown,
    }: JumbotronTypes.TJumbotronTitleLocationMulti,
    ref: any
  ) => {
    return (
      <div ref={ref} className={'LocationsContainerDiv'}>
        <Container className="LocationsContainer">
          <Columns
            className="multiLocationColumns is-flex"
            multiline={false}
            marginless
          >
            <Column
              className="is-half is-flex locationLinkColumn is-align-items-center"
              marginless
              paddingless
            >
              <a
                href={
                  selectedLocation?.address &&
                  selectedLocation?.address[0]!.link
                }
                className={`is-flex is-align-items-center ${
                  isImage ? 'has-text-secondary' : 'has-text-primary'
                }`}
                target="_blank"
              >
                <FontAwesomeIcon
                  iconClassName={
                    (selectedLocation?.address &&
                      selectedLocation?.address[0]?.icon?.iconClassName!) ||
                    'FaMapMarker'
                  }
                  iconContextProps={{
                    className: `${
                      isImage ? 'has-text-secondary' : 'has-text-primary'
                    } pr-3`,
                    style: {
                      fontSize:
                        (selectedLocation?.address &&
                          selectedLocation?.address[0]?.icon?.size) ||
                        28,
                    },
                  }}
                />
                {selectedLocation?.address && selectedLocation?.address[0].data}
              </a>
            </Column>
            <Column
              className="is-flex is-flex-direction-column locationDropDownColumn"
              marginless
              paddingless
            >
              <div className="divButtonWrapper is-flex is-justify-content-flex-end">
                <button
                  className="button"
                  onClick={() => {
                    onDropDownButton();
                  }}
                >
                  <span className="dropdownText has-text-secondary">
                    Check Our Other Facility
                  </span>

                  <FontAwesomeIcon
                    iconClassName={dropDownState ? 'FaArrowDown' : 'FaArrowUp'}
                    iconContextProps={{
                      className: `has-text-secondary pl-3`,
                      style: {
                        fontSize:
                          (selectedLocation?.address &&
                            selectedLocation?.address[0]?.icon?.size) ||
                          28,
                      },
                    }}
                  />
                </button>
              </div>
            </Column>
          </Columns>
          {dropDownState && (
            <div className="box jumbotronBox mt-2">
              {locationData.map((contactDetail): any => (
                <Tile
                  kind="ancestor"
                  className="AncestorTile"
                  key={weakKey(location)}
                >
                  <Tile kind="parent" className="ParentTile">
                    <Tile kind="child" className="ChildTileOne">
                      <div>
                        <p className="subtitle1">
                          {contactDetail.facilityName}
                        </p>
                        <Text>{contactDetail.address[0].data}</Text>
                      </div>
                    </Tile>
                    <Tile kind="child" className={`ChildTileTwo`}>
                      <Button
                        size={`medium is-normal-mobile`}
                        className={'cp-primary-button-highlighted'}
                        onClick={() => {
                          selectItemFromDropDown(contactDetail);
                        }}
                      >
                        {/* <Link
                              to={'/'}
                              className={
                                variant === 'multiLocationListOfCard'
                                  ? 'small'
                                  : 'medium'
                              }
                            > */}
                        <Text className="has-text-white">Visit</Text>
                        {/* </Link> */}
                      </Button>
                    </Tile>
                  </Tile>
                </Tile>
              ))}
            </div>
          )}
        </Container>
        <div className="pageContentTitle is-flex is-align-items-center is-flex-direction-column">
          {!dropDownState && <h1>{selectedLocation.facilityName}</h1>}
        </div>
      </div>
    );
  }
);
